<template>
  <v-row class="match-height">
    <v-col cols="12" md="12">
      <v-card>
        <v-card-title>Projet Planning : {{ $route.params.id }}</v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12" md="3">
                <label for="emailHorizontalIcons">Nom de l'application</label>
              </v-col>

              <v-col cols="12" md="9">
                <v-text-field
                  id="applicationName"
                  v-model="planningProject.applicationName"
                  outlined
                  dense
                  disabled
                  placeholder="Nom de l'application"
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <label for="emailHorizontalIcons">URL de l'application</label>
              </v-col>

              <v-col cols="12" md="9">
                <v-text-field
                  id="URL de l'application"
                  v-model="planningProject.appURL"
                  outlined
                  dense
                  disabled
                  placeholder="URL de l'application"
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <label for="emailHorizontalIcons">URL par défaut</label>
              </v-col>

              <v-col cols="12" md="9">
                <v-text-field
                  id="URL par défaut"
                  v-model="planningProject.relativeURL"
                  outlined
                  dense
                  disabled
                  placeholder="URL par défaut"
                  hide-details
                ></v-text-field>
              </v-col>


              <v-col cols="12" md="3">
                <label for="emailHorizontalIcons">URL de LastMile <span lass="required">*</span></label>
              </v-col>

              <v-col cols="12" md="9">
                <v-text-field
                  id="URL de lastMile"
                  v-model="planningProject.lastMileUrl"
                  :prepend-inner-icon="icons.mdiWeb"
                  outlined
                  dense
                  placeholder="URL de LastMile"
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <label for="email">Email de l'admin</label>
              </v-col>

              <v-col cols="12" md="9">
                <v-text-field
                  id="email"
                  v-model="planningProject.email"
                  outlined
                  dense
                  disabled
                  placeholder="Email"
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <label for="emailHorizontalIcons">Statut SSL</label>
              </v-col>
              <v-col cols="12" md="9">
                <v-text-field
                  id="firstnameHorizontalIcons"
                  v-model="planningProject.SSLStatus"
                  outlined
                  dense
                  disabled
                  placeholder="Logo"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <label for="emailHorizontalIcons">Log SSL</label>
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  id="firstnameHorizontalIcons"
                  v-model="planningProject.SSLLog"
                  outlined
                  auto-grow
                  dense
                  disabled
                  placeholder="Logo"
                  hide-details
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="3">
                <label for="email">API Key</label>
              </v-col>

              <v-col cols="12" md="9">
                <v-text-field
                  id="email"
                  v-model="planningProject.apiKey"
                  outlined
                  dense
                  placeholder="API Key"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <label for="firstnameHorizontalIcons">Logo</label>
              </v-col>

              <v-col cols="12" md="9">
                <v-text-field
                  id="firstnameHorizontalIcons"
                  v-model="planningProject.logo"
                  outlined
                  dense
                  placeholder="Logo"
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <label for="firstnameHorizontalIcons">Formulaires désactivés ISC</label>
              </v-col>

              <v-col cols="12" md="9">
                <v-text-field
                  id="firstnameHorizontalIcons"
                  v-model="planningProject.disabledForms"
                  :prepend-inner-icon="icons.mdiCameraImage"
                  outlined
                  dense
                  placeholder="Formulaires désactivés ISC"
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <label for="emailHorizontalIcons">Statut notification email</label>
              </v-col>
              <v-col cols="12" md="9">
                <v-switch
                  class="mt-0"
                  id="flagEmail"
                  v-model="planningProject.flagEmail"
                  outlined
                  dense
                  hide-details
                ></v-switch>
              </v-col>

              <v-col cols="12" md="3">
                <label for="emailHorizontalIcons">Statut notification agenda</label>
              </v-col>

              <v-col cols="12" md="9">
                <v-switch
                  class="mt-0"
                  id="flagTaskAgenda"
                  v-model="planningProject.flagTaskAgenda"
                  outlined
                  dense
                  hide-details
                ></v-switch>
              </v-col>

              <v-col cols="12" md="3">
                <label for="emailHorizontalIcons">Statut facture impayée</label>
              </v-col>

              <v-col cols="12" md="9">
                <v-switch
                  class="mt-0"
                  id="flagAlertInvoice"
                  v-model="planningProject.flagAlertInvoice"
                  outlined
                  dense
                  hide-details
                ></v-switch>
              </v-col>
              <v-col offset-md="3" cols="9">
                <v-alert
                  style="margin-top:20px;margin-bottom:0px"
                  :color="statutAlert === 'INFO' ? 'primary' : 'error'"
                  v-show="message"
                  dense
                  dismissible
                  outlined
                  prominent
                  :type="statutAlert === 'INFO' ? 'info' : 'error'"
                  >{{ message }}</v-alert
                >
              </v-col>
              <v-col offset-md="3" cols="12">
                <v-btn color="primary" @click="updatePlanningProject">
                  Enregistrer
                </v-btn>
                <v-btn
                  color="warning"
                  @click="generateSSLPlanningProject"
                  :disabled="!enableSecureSSL || planningProject.SSLStatus == 'DONE'"
                  style="margin-left:10px;"
                >
                  Sécuriser en HTTPS
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiEmailOutline, mdiCellphone, mdiLockOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import PlanningProjectAPI from '../../api/PlanningProjectAPI'

export default {
  methods: {
    retrievePlanningProject() {
      PlanningProjectAPI.getPlanningProject(this.$route.params.id)
        .then(response => {
          this.planningProject = response.data;
          if (this.planningProject.appURL.includes('https')) {
            this.enableSecureSSL = true;
          }
        })
        .catch(e => {
          this.message = e
          console.log(e)
        })
    },
    generateSSLPlanningProject() {
      PlanningProjectAPI.generateSSLPlanningProject(this.$route.params.id, {})
        .then(response => {
          console.log(response.data)
          this.statutAlert = 'INFO';
          this.message = (response && response.data && response.data.message) || 'Génération de certification en cours, le serveur sera éteint pendant quelques secondes';
          setTimeout(() => this.$router.push({ path: '/' }), 15000)
        })
        .catch(e => {
          this.message = (e && e.error) || 'Erreur inconnu, veuillez réessayer ultérieurement'
        })
    },
    updatePlanningProject() {
      PlanningProjectAPI.putPlanningProjects(this.$route.params.id, {
        lastMileUrl: this.planningProject.lastMileUrl,
        apiKey: this.planningProject.apiKey,
        logo: this.planningProject.logo,
        flagEmail: this.planningProject.flagEmail,
        flagTaskAgenda: this.planningProject.flagTaskAgenda,
        flagAlertInvoice: this.planningProject.flagAlertInvoice,
        disabledForms: this.planningProject.disabledForms
      })
        .then(response => {
          this.statutAlert = 'INFO'
          this.message = 'Le projet planning a été mis à jour avec succès ! '
          setTimeout(() => this.$router.push({ path: '/' }), 1500)
        })
        .catch(e => {
          console.log('error', e)
          this.statutAlert = 'ERROR'
          this.message = (e && e.message) || e
        })
    },
  },
  data() {
    return {
      planningProject: {
        idApplicationName: '',
        applicationName: '',
        appURL: '',
        lastMileUrl: '',
        relativeURL: '',
        apiKey: '',
        email: '',
        logo: '',
        disabledForms: '',
        flagEmail: false,
        flagTaskAgenda: false,
        flagAlertInvoice: false,
        enableSecureSSL: true
      },
      message: '',
      statutAlert: 'ERROR',
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCellphone,
        mdiLockOutline,
      },
    }
  },
  created() {
    this.retrievePlanningProject()
  },
}
</script>
